import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.26.3_next@15.1.6_babel-plugin-react-compiler@19.0.0-beta-decd7b8-20250118_r_8de42f0cae95b224e49652d621c3fd71/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.26.3_next@15.1.6_babel-plugin-react-compiler@19.0.0-beta-decd7b8-20250118_r_8de42f0cae95b224e49652d621c3fd71/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.26.3_next@15.1.6_babel-plugin-react-compiler@19.0.0-beta-decd7b8-20250118_r_8de42f0cae95b224e49652d621c3fd71/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.6_babel-plugin-react-compiler@19.0.0-beta-decd7b8-20250118_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Geist\",\"arguments\":[{\"variable\":\"--font-geist-sans\",\"subsets\":[\"latin\"]}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.6_babel-plugin-react-compiler@19.0.0-beta-decd7b8-20250118_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Geist_Mono\",\"arguments\":[{\"variable\":\"--font-geist-mono\",\"subsets\":[\"latin\"]}],\"variableName\":\"geistMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/toaster.tsx");
